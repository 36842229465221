import {
  CraneOperatorKind,
  CraneOperatorStartingPoint,
  FournisseurType,
  OrdreDeTravailAddendumKind,
  OrdreDeTravailAttachmentKind,
  OrdreDeTravailType,
  WeekType,
  WorkTypeCategory,
} from '../../generated/schemas';

const overview = {
  serviceCall: 'Service call',
  client: 'Client',
  date: 'Date',
  gtNumber: 'GT Number',
  representative: 'Project manager',
  representativeNo: 'Project manager no.',
  title: 'Overview',
  assignmentInformation: 'Assigment information',
  noSubcontracting: 'No subcontractor',
  noReplacement: 'No crane replacement',
  secondServing: 'Second serving',
  requirements: 'Client requirements',
  noPo: 'PO #',
  generalNotes: 'Notes',
};

const workInfo = {
  accessories: 'Accessories to bring',
  boomLength: 'Boom length',
  counterweight: 'Counterweight',
  description: 'Work description',
  jibLength: 'Jib length',
  maxWeightLift: 'Maximum weight to lift',
  offset: 'Offset',
  title: 'Work',
  workRadius: 'Work radius',
  craneType: 'Crane type',
  notAssigned: 'Not assigned',
};

const weekType = {
  [WeekType.FourDaysTenHours]: 'Standard 4d x 10h',
  [WeekType.FourDaysTenHoursOneDayFiveHours]: 'Standard 4d x 10h + 1d x 5h',
  [WeekType.FiveDaysEightHours]: 'Standard 5d x 8h',
  [WeekType.FiveDaysNineHours]: 'Standard 5d x 9h',
  [WeekType.FiveDaysTenHours]: 'Standard 5d x 10h',
};

const workTypeCategory = {
  [WorkTypeCategory.Airports]: 'Airports',
  [WorkTypeCategory.Landscaping]: 'Landscaping',
  [WorkTypeCategory.Antennas]: 'Antennas',
  [WorkTypeCategory.AqueductsSewer]: 'Aqueducts and Sewers',
  [WorkTypeCategory.Dams]: 'Dams',
  [WorkTypeCategory.RetentionBasins]: 'Retention basins',
  [WorkTypeCategory.Blade]: 'Blade Bearing',
  [WorkTypeCategory.Cages]: 'Moving cages',
  [WorkTypeCategory.PowerPlants]: 'Power plants',
  [WorkTypeCategory.Chambers]: 'Chambers',
  [WorkTypeCategory.Demolition]: 'Demolition',
  [WorkTypeCategory.Distribution]: 'Distribution',
  [WorkTypeCategory.Pruning]: 'Pruning',
  [WorkTypeCategory.Excavation]: 'Excavation',
  [WorkTypeCategory.Gearboxes]: 'Gearboxes',
  [WorkTypeCategory.Generator]: 'Generator',
  [WorkTypeCategory.Lifting]: 'Equipment lifting',
  [WorkTypeCategory.Lines]: 'Lines',
  [WorkTypeCategory.Accessory]: 'Accessory rental',
  [WorkTypeCategory.NonOperated]: 'Non-operated rental',
  [WorkTypeCategory.Hand]: 'Hand',
  [WorkTypeCategory.Handling]: 'Handling',
  [WorkTypeCategory.Modules]: 'Modules',
  [WorkTypeCategory.Gondola]: 'Gondola/Suspended basket',
  [WorkTypeCategory.NewConstruction]: 'New construction/Expansion',
  [WorkTypeCategory.Bridges]: 'Bridges/Viaducts',
  [WorkTypeCategory.Substations]: 'Substations/Stations',
  [WorkTypeCategory.Pylons]: 'Pylons',
  [WorkTypeCategory.Docks]: 'Docks',
  [WorkTypeCategory.Refurbishment]: 'Refurbishment',
  [WorkTypeCategory.Rotor]: 'Rotor',
  [WorkTypeCategory.RoadsRails]: 'Roads/Rails/Subways',
  [WorkTypeCategory.Shelters]: 'Shelters',
  [WorkTypeCategory.Towers]: 'Towers',
  [WorkTypeCategory.Transformers]: 'Transformers',
  [WorkTypeCategory.Transport]: 'Transport',
  [WorkTypeCategory.VentilationPlumbingElectrical]: 'Ventilation/Plumbing/Electrical',
};

const worksite = {
  address: 'Worksite address',
  arrivalDateTime: 'Arrival date',
  callReceivedFrom: 'Call received from',
  contact: 'Worksite meet',
  departureDateTime: 'Departure date',
  name: 'Worksite name',
  phone: 'Worksite phone',
  title: 'Worksite',
  readyDateTime: 'Ready date',
  workType: 'Work type',
  workTypeCategory: 'Work type category',
  estimatedDurationInDays: 'Duration (days)',
  estimatedDurationInHours: 'Duration (hours)',
  estimatedDurationInDaysUnit: 'days',
  estimatedDurationInHoursUnit: 'hours',
  weekType: 'Week type',
  select: 'Select a date',
  requirements: 'Worksite requirements',
  establishedSchedule: 'Established schedule',
  hasWorksiteBeenVisited: 'Worksite visited',
  startTime: 'Start',
  endTime: 'End',
};

const crane = {
  capacity: 'Required capacity',
  contractMinimum: 'Contract minimum',
  minimumHours: 'minimum hour(s)',
  unitNo: 'Unit No',
  operatorAssistants: 'Operator assistant',
  operators: 'Crane operators',
  title: 'Crane',
  startingPointLabel: 'Starting point',
  departure: 'Departure',
  arrival: 'Arrival',
  ready: 'Ready',
  startingPoint: {
    [CraneOperatorStartingPoint.Branch]: 'Branch',
    [CraneOperatorStartingPoint.WorkSite]: 'Worksite',
  },
  operatorKind: {
    [CraneOperatorKind.AdditionalMan]: 'Additional man',
    [CraneOperatorKind.Electrician]: 'Electrician',
    [CraneOperatorKind.Engineer]: 'Engineer',
    [CraneOperatorKind.Helper]: 'Helper',
    [CraneOperatorKind.IndustrialDesigner]: 'Industrial designer',
    [CraneOperatorKind.Operator]: 'Operator',
    [CraneOperatorKind.Signalman]: 'Signalman',
    [CraneOperatorKind.SiteSupervisor]: 'Site supervisor',
    [CraneOperatorKind.TechnicalSupervisor]: 'Technical supervisor',
    [CraneOperatorKind.Welder]: 'Welder',
    [CraneOperatorKind.Trucker]: 'Trucker',
    [CraneOperatorKind.Mechanic]: 'Mechanic',
    [CraneOperatorKind.Rigger]: 'Rigger',
    [CraneOperatorKind.ReplacedOperator]: 'Replaced Operator',
  },
};

const attachments = {
  title: 'Attachments',
  displayName: 'Name',
  type: 'Type',
  add: 'Add',
  kind: {
    [OrdreDeTravailAttachmentKind.AssemblySequence]: 'Assembly Sequence',
    [OrdreDeTravailAttachmentKind.BargeStabilityStudy]: 'Barge Stability Study',
    [OrdreDeTravailAttachmentKind.BargeStowageInspection]: 'Barge Stowage Inspection',
    [OrdreDeTravailAttachmentKind.Certifications]: 'Certifications',
    [OrdreDeTravailAttachmentKind.Charters]: 'Charters',
    [OrdreDeTravailAttachmentKind.CompetenceCards]: 'Competence Cards',
    [OrdreDeTravailAttachmentKind.EngineeringCertificate]: 'Engineering Certificate',
    [OrdreDeTravailAttachmentKind.FeasibilityStudy]: 'Feasibility Study',
    [OrdreDeTravailAttachmentKind.GroundPressureCalculations]: 'ST - Ground Pressure Calculation',
    [OrdreDeTravailAttachmentKind.InsuranceDeclaration]: 'Insurance Declaration',
    [OrdreDeTravailAttachmentKind.LiftingPlan1Crane]: 'ST - Lifting Plan One Crane',
    [OrdreDeTravailAttachmentKind.LiftingPlan2Cranes]: 'ST - Lifting Plan Two Cranes',
    [OrdreDeTravailAttachmentKind.LiftingPlanReview]: 'ST - Lifting Plan Review',
    [OrdreDeTravailAttachmentKind.ListOfTransports]: 'List of Transports',
    [OrdreDeTravailAttachmentKind.Location]: 'Location',
    [OrdreDeTravailAttachmentKind.Others]: 'Others',
    [OrdreDeTravailAttachmentKind.ParkingPermit]: 'Parking Permit',
    [OrdreDeTravailAttachmentKind.Photos]: 'Photos',
    [OrdreDeTravailAttachmentKind.ProjectDocuments]: 'Project Documents',
    [OrdreDeTravailAttachmentKind.RouteSpecifications]: 'ST - Road Specifications',
    [OrdreDeTravailAttachmentKind.Signage]: 'Signage',
    [OrdreDeTravailAttachmentKind.SignalingPermit]: 'Signaling Permit',
    [OrdreDeTravailAttachmentKind.TechnicalDrawings]: 'Technical Drawings',
    [OrdreDeTravailAttachmentKind.TransportPermit]: 'Transport Permit',
    [OrdreDeTravailAttachmentKind.TravelSpecifications]: 'ST - Travel Specifications',
    [OrdreDeTravailAttachmentKind.WorkMethodEventSealedByEngineer]: 'ST - Work Method (Event) Sealed By Engineer',
  },
  noData: 'No attachments',
};

const addenda = {
  title: 'Addenda',
  description: 'Description',
  type: 'Type',
  add: 'Add',
  kind: {
    [OrdreDeTravailAddendumKind.Archived]: 'Archived',
    [OrdreDeTravailAddendumKind.ClientOrWorksite]: 'Client or Worksite',
    [OrdreDeTravailAddendumKind.CraneOperator]: 'Crane Operator',
    [OrdreDeTravailAddendumKind.Dispatching]: 'Dispatching',
    [OrdreDeTravailAddendumKind.Quote]: 'Quote',
    [OrdreDeTravailAddendumKind.InternalNote]: 'Internal Note',
    [OrdreDeTravailAddendumKind.Invoicing]: 'Invoicing',
    [OrdreDeTravailAddendumKind.SalesSupervisor]: 'Sales Supervisor',
    [OrdreDeTravailAddendumKind.SupervisionNote]: 'Supervision Note',
  },
  noData: 'No addenda',
};

const fournisseur = {
  add: 'Add',
  fournisseurs: 'Suppliers',
  name: 'Supplier',
  numeroDeCommandeAchat: 'Supplier purchase order number',
  noData: 'No suppliers',
  description: 'Description',
  departure: 'Departure',
  subcontractor: 'Subcontractor',
  billingCode: 'Billing code',
  planned: 'Planned',
  type: {
    label: 'Type',
    [FournisseurType.Transporter]: 'Transporter',
    [FournisseurType.Other]: 'Other',
  },
};

const craneConfiguration = {
  title: 'Crane Configuration',
  boomLength: 'Boom',
  jibLength: 'Jib',
  counterweight: 'Counterweight',
  mainCraneConfiguration: 'Main Crane Configuration',
  mainCrane: 'Main Crane',
  additionalCrane: 'Additional Crane',
  offset: 'Offset',
  noData: 'No Configurations',
};

const advancementFollowup = {
  contractAgreement: 'Contract agreement',
  timesheet: 'Timesheet',
  title: 'Advancement follow-up',
  cannotAddContractAgreement: 'Cannot add a contract agreement when the Ordre de Travail is "{{ status }}"',
  codesDeFacturation: 'Codes de facturation',
  addContractAgreementButton: 'Create',
};

const addTimesheetModal = {
  title: 'Add a contract agreement',
  withOperator: 'With operator',
  withoutOperator: 'Without operator',
  subtitle: 'Select the crane operator and the kind.',
  employee: 'Crane operator',
  kind: 'Role',
  cancel: 'Cancel',
  add: 'Add',
  error: 'Failed to add the timesheet',
  noOperators: 'Could not retrieve the operators.',
};

const headers = {
  number: 'OT Number',
  serviceCall: 'Service call',
  branch: 'Dispatch branch',
  unitNo: crane.unitNo,
  client: overview.client,
  operators: crane.operators,
  documentStatus: 'OT Status',
  date: overview.date,
  workType: 'Nature of the work',
  contractMinimum: 'Minimum contract hours',
  requiredCapacity: 'Required capacity',
  workDescription: 'Work description',
  maximumWeightToLift: 'Max. Weight to Lift',
  representativeName: 'Project manager',
  site: 'Worksite',
};

const list = {
  title: 'Ordres de travail',
  headers,
};

const detail = {
  title: 'Ordre de travail',
  edit: 'Edit',
  saveSuccessMessage: 'Modifications to ordre de travail saved successfully',
  cancel: 'Cancel',
  save: 'Save',
  exportButton: 'Export',
  warnings: 'Warnings',
  confirm: 'Confirm',
  otStatusChangeWarning:
    'The change in status will result in the creation of a contract agreement associated with the ordre de travail. Do you wish to proceed? This action is irreversible.',
  otStatusChangeError: 'The change in status failed. Please try again.',
  otContinuityUpdateWarning: 'The modification of the field will be applied to all OTs which have the same number.',
};

const detailTabs = {
  informationsTab: 'Informations',
  contractAgreementTab: 'Contract agreements',
};

const requiredValidation = {
  arrivalDateTime: `The ${worksite.arrivalDateTime.toLowerCase()} is required.`,
  callReceivedFrom: `The ${worksite.callReceivedFrom.toLowerCase()} is required.`,
  date: `The ${overview.date.toLowerCase()} is required.`,
  departureDateTime: `The ${worksite.departureDateTime.toLowerCase()} is required.`,
  workDescription: `The ${workInfo.description.toLowerCase()} is required.`,
  addendumKind: `The ${addenda.type.toLowerCase()} is required.`,
  addendumDescription: `The ${addenda.description.toLowerCase()} is required.`,
  fournisseurName: `The ${fournisseur.name.toLowerCase()} is required.`,
  fournisseurDescription: `The ${fournisseur.description.toLowerCase()} is required.`,
  fournisseurBillingCode: `The ${fournisseur.billingCode.toLocaleLowerCase()} is required.`,
  fournisseurNumeroDeCommandeAchat: `The ${fournisseur.numeroDeCommandeAchat.toLowerCase()} is required.`,
  estimatedDurationInHours: `The ${worksite.estimatedDurationInHours.toLowerCase()} is required.`,
  representative: `The ${overview.representative.toLowerCase()} is required.`,
  worksite: `The ${worksite.title.toLowerCase()} is required.`,
  workType: `The ${worksite.workType.toLowerCase()} is required.`,
  workTypeCategory: `The ${worksite.workTypeCategory.toLowerCase()} is required.`,
  purchaseOrder: `The ${overview.noPo.toLowerCase()} is required.`,
  attachmentName: `The ${attachments.displayName.toLowerCase()} is required.`,
};

const yupTestValidation = {
  invalidTime: 'Invalid time value',
  arrivalBeforeDepartureTime: `${worksite.arrivalDateTime} must follow ${worksite.departureDateTime}`,
  readyBeforeArrivalTime: `${worksite.readyDateTime} must follow ${worksite.arrivalDateTime}`,
  estimatedDurationInHoursInvalidInterval: `${worksite.estimatedDurationInHours} must have 0.5 intervals`,
  endTimeBeforeStartTime: `${worksite.endTime} must follow ${worksite.startTime}`,
};

const requirementsModal = {
  close: 'Close',
};

const billingRequirementDescriptionModal = {
  title: 'Billing requirements',
  openButtonLabel: 'Billing requirements',
};

const ordreDeTravailTypes = {
  accessoryRental: 'Accessories Rental',
  bare: 'Bare Crane',
  laborRental: 'Labor Rental',
  laborRentalWithCrane: 'Labor Rental',
  craneOperatorLaborRental: 'Labor Rental',
  liftingPlan: 'Lifting Plan',
  liftingTest: 'Lifting Test',
  operatedHourly: 'Operated Crane (hourly)',
  operatedMonthly: 'Operated Crane (monthly)',
  rollingEquipment: 'Rolling Equipment',
  storage: 'Storage',
} satisfies Record<OrdreDeTravailType, string>;

export const ordreDeTravail = {
  unitFeet: 'feet',
  unitPounds: 'lbs',
  unitTons: 'tons',
  submitted: 'Submitted',
  yes: 'Oui',
  no: 'Non',
  advancementFollowup,
  crane,
  detail,
  detailTabs,
  list,
  overview,
  workInfo,
  worksite,
  weekType,
  workTypeCategory,
  requiredValidation,
  yupTestValidation,
  addTimesheetModal,
  requirementsModal,
  attachments,
  addenda,
  fournisseur,
  billingRequirementDescriptionModal,
  ordreDeTravailTypes,
  craneConfiguration,
};
