import { addDays, areIntervalsOverlapping, differenceInMinutes } from 'date-fns';
import { TimeRange } from '../../types/TimeRange';
import { formatMinutesToHourString, formatTime, newDateFromTimeString, newDateFromTimeStringAndDuration } from './DateTimeHelper';
import { getTimeRangeToTime, midnight, sortTimeRanges } from './TimeRangeWithoutToHelpers';
import { TimeRangeOrPremiumTableData } from '../components/TimeRangeTable/TimeRangeTableData';
import { ServiceHoursType } from '../../validationNext/models/models';

export const timeStringLength = 5;
export const minutesInADay = 1440;

export const findLatestEndTime = (serviceHours: TimeRange[]): [time: string, startOnNextDay?: boolean | null] => {
  if (!serviceHours.length) return [midnight, false];
  const sorted = serviceHours.slice(0).sort(sortTimeRanges);
  const latest = sorted[sorted.length - 1];
  return [getTimeRangeToTime(latest), rangeFinishesTomorrow(latest)];
};

export const getNewAvailableTimeRange = (serviceHours: TimeRange[], date?: Date | string | null, duration = 60): TimeRange => {
  const [latestEndTime, startOnNextDay] = findLatestEndTime(serviceHours);
  const from = newDateFromTimeString(latestEndTime, date);
  return { from: formatTime(from), startsOnNextDay: startOnNextDay ?? false, durationInMinutes: duration };
};

export const isOverlap = (values: TimeRange[] | undefined | null, timeRange: TimeRange): boolean => {
  const overlappingTimeRanges = values?.filter((tr: TimeRange) => {
    const from = newDateFromTimeString(tr.from);
    const to = newDateFromTimeStringAndDuration(tr.from, tr.durationInMinutes || 0);
    const timeRangeFrom = newDateFromTimeString(timeRange.from);
    const timeRangeTo = newDateFromTimeStringAndDuration(timeRange.from, timeRange.durationInMinutes || 0);
    if (!to || !timeRangeTo) return false;
    if (tr.startsOnNextDay) {
      from.setDate(from.getDate() + 1);
      to.setDate(to.getDate() + 1);
    }
    if (timeRange.startsOnNextDay) {
      timeRangeFrom.setDate(timeRangeFrom.getDate() + 1);
      timeRangeTo.setDate(timeRangeTo.getDate() + 1);
    }
    try {
      return areIntervalsOverlapping({ start: from, end: to }, { start: timeRangeFrom, end: timeRangeTo }, { inclusive: false });
    } catch {
      return false;
    }
  });
  return overlappingTimeRanges ? overlappingTimeRanges?.length >= 1 : false;
};

export const isFromBiggerThanTo = (timeRange: TimeRange | null | undefined): boolean => {
  if (!timeRange?.from && !timeRange?.durationInMinutes) return false;
  const to = newDateFromTimeStringAndDuration(timeRange.from, timeRange.durationInMinutes || 0);
  if (!to) return false;
  return !!timeRange && timeRange?.durationInMinutes <= 0;
};

export const getTimeRangeDurationInMinutes = (from: string, to?: string | null, date?: Date | string | null): number => {
  if (!to) return 0;
  const fromDate = newDateFromTimeString(from, date);
  const toDate = to === midnight ? newDateFromTimeString(to, date, true) : newDateFromTimeString(to, date);
  return differenceInMinutes(toDate, fromDate) || 0;
};

export const getTotalDurationInMinutes = (durations: { durationInMinutes: number }[] = []): number =>
  durations.reduce(
    (acc, current) => (!!current.durationInMinutes && current.durationInMinutes > 0 ? current.durationInMinutes : 0) + acc,
    0,
  );

export const getTotalDurationInHours = (timeRanges?: TimeRange[] | null, locale?: string): string => {
  const totalDurationInMinutes: number = getTotalDurationInMinutes(timeRanges ?? []);
  return formatMinutesToHourString(totalDurationInMinutes, locale);
};

export const getTimeRangeDurationInHours = (timeRange?: TimeRange): string => {
  return formatMinutesToHourString(timeRange?.durationInMinutes || 0);
};

export const rangeFinishesTomorrow = (timeRange: TimeRange): boolean => {
  if (timeRange.startsOnNextDay) return true;
  if (!timeRange.durationInMinutes) return false;
  const from = newDateFromTimeString(timeRange.from);
  const to = newDateFromTimeStringAndDuration(timeRange.from, timeRange.durationInMinutes || 0);
  if (!to) return false;
  return from.getDate() !== to.getDate();
};

export const getTotalServiceHoursDurationInMinutes = (
  serviceHours: {
    from: string;
    durationInMinutes: number;
    activity: ServiceHoursType;
  }[],
): number => {
  const serviceHour: ServiceHoursType = 'serviceHour';
  const filteredServiceHours = serviceHours.filter((sh) => sh.activity === serviceHour);
  return getTotalDurationInMinutes(filteredServiceHours);
};
export const calculateUpdateDuration = (
  field: TimeRangeOrPremiumTableData,
  newFromValue: string | undefined,
  newToValue: string | undefined,
  ordreDeTravailDate: Date,
): number => {
  const previousFinishesTomorrow = rangeFinishesTomorrow({
    from: field?.from || '',
    durationInMinutes: field.durationInMinutes,
  });

  const dateToUse = field.startsOnNextDay ? addDays(ordreDeTravailDate, 1) : ordreDeTravailDate;

  const durationInMinutes =
    newFromValue !== undefined
      ? getTimeRangeDurationInMinutes(newFromValue, field.to, dateToUse)
      : getTimeRangeDurationInMinutes(field.from, newToValue, dateToUse);

  return previousFinishesTomorrow ? (durationInMinutes || 0) + minutesInADay : durationInMinutes || 0;
};
