import { createTheme, darken, ThemeOptions } from '@mui/material';

import {
  appBarColor,
  black,
  black04,
  charcoal,
  colors,
  darkButtonColor,
  darkGreen,
  defaultBackgroundColor,
  defaultBorderColor,
  defaultDarkColor,
  defaultPrimaryColor,
  defaultTextColor,
  defaultWarnColor,
  green,
  greenPeas,
  lightPink,
  red,
  white,
} from '../common/constants/colors';
import { ExtendedTypographyOptions } from './extensionInterfaces';

// A custom theme for this app
export default createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'success' },
          style: {
            backgroundColor: darkGreen,
            color: white,
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: { root: { backgroundColor: appBarColor } },
    },
    MuiButton: {
      defaultProps: {},
      styleOverrides: {
        containedPrimary: {
          '&:hover': {
            backgroundColor: darken(white, 0.275),
          },
          borderRadius: 4,
          backgroundColor: white,
          color: 'black',
        },
        // Here we swap color to make <Button color="secondary"/> appears grey, just like in mock-ups
        containedSecondary: {
          '&:hover': {
            backgroundColor: darken(darkButtonColor, 0.275),
          },
          borderRadius: 4,
          backgroundColor: darkButtonColor,
          color: '#FFFFFF',
        },
        outlinedPrimary: {
          '&:hover': {
            borderColor: darken(defaultBorderColor, 0.275),
            color: darken(white, 0.275),
          },
          borderColor: defaultBorderColor,
          color: white,
        },

        root: {
          borderRadius: 4,
          fontWeight: 400,
          textTransform: 'initial',
          '&:hover': {
            backgroundColor: black04,
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { color: 'error' },
          style: {
            backgroundColor: colors.chip.red,
          },
        },
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: defaultWarnColor,
            color: '#000',
            fill: '#000',
          },
        },
        {
          props: { color: 'info' },
          style: {
            backgroundColor: colors.chip.deepPurple,
          },
        },
        {
          props: { color: 'success' },
          style: {
            backgroundColor: colors.chip.green,
            color: '#fff',
            fill: '#fff',
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: colors.chip.yellow,
          },
        },
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: colors.chip.blue,
          },
        },
        {
          props: { color: 'default' },
          style: {
            backgroundColor: colors.chip.white,
          },
        },

        {
          props: { variant: 'lightPink' },
          style: {
            backgroundColor: colors.chip.lightPink,
          },
        },
        {
          props: { variant: 'greenPeas', color: greenPeas },
          style: {
            backgroundColor: colors.chip.greenPeas,
          },
        },
        {
          props: { variant: 'charcoal', color: charcoal },
          style: {
            backgroundColor: colors.chip.charcoal,
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: defaultBackgroundColor,
          borderRadius: '0.375rem',
          color: defaultPrimaryColor,
          fontWeight: 600,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: black,
            '&:focus, &:hover': {
              backgroundColor: black,
            },
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontFamily: 'Inter',
          fontSize: '0.875rem',
          fontWeight: 400,
        },
        root: {
          backgroundColor: defaultPrimaryColor,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {},
    },
  },
  palette: {
    background: {
      default: defaultBackgroundColor,
    },
    error: {
      main: '#ED1B24',
    },
    primary: {
      main: defaultPrimaryColor,
    },
    secondary: {
      main: white,
    },
    success: {
      main: green,
    },
    warning: {
      main: '#F39302',
    },
    info: {
      main: defaultDarkColor,
    },
    greenPeas: {
      main: greenPeas,
      contrastText: defaultTextColor,
    },
    lightPink: {
      main: lightPink,
      contrastText: white,
    },
    charcoal: {
      main: charcoal,
      contrastText: white,
    },
  },
  typography: {
    body1: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    caption: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    link: {
      textDecoration: 'underline',
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: '1px',
      color: defaultPrimaryColor,
      '&:hover': {
        borderColor: red,
        color: red,
      },
    },
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontFamily: ['Inter'].join(','),
      fontSize: '1.3rem',
      fontWeight: 500,
      marginBottom: '0.85rem',
    },
    h2: {
      fontFamily: ['Inter'].join(','),
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h3: {
      fontFamily: ['Inter'].join(','),
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    subtitle1: {
      fontFamily: ['Inter'].join(','),
      fontSize: '1rem',

      fontWeight: 600,
    },
  } as ExtendedTypographyOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
      popMenu: 1360,
    },
  },
} as ThemeOptions);
