import { WorkKindNumberValue } from '../../common/enums/WorkKindEnum';
import { OrdreDeTravailValidationStatus, TimeSheetEntryValidationStatus } from '../../generated/schemas';
import { CompilablePremiumsEnum, NonCompilablePremiumsEnum } from '../../timesheet/enums/PremiumsEnum';
import { AdditionalHourType } from '../../validationNext/components/contractAgreement/AdditionalHours';

const button = {
  add: 'Ajouter',
  close: 'Fermer',
  save: 'Enregistrer',
  submit: 'Soumettre tout',
  validateAndContinue: 'Valider et continuer',
  submitTimeSheets: 'Soumettre les cartes de temps',
  expandAll: 'Ouvrir tout',
  collapseAll: 'Fermer tout',
  filter: 'Filtrer',
  onlyNotSubmitted: 'Afficher uniquement les non-soumises',
};

const validationsStatuses = {
  [OrdreDeTravailValidationStatus.DocumentsReceived]: 'Documents reçus',
  [OrdreDeTravailValidationStatus.Open]: 'Ouvert',
  [OrdreDeTravailValidationStatus.Submitted]: 'Soumis',
  [OrdreDeTravailValidationStatus.Upcoming]: 'À venir',
  [OrdreDeTravailValidationStatus.Entering]: 'En saisie',
  [OrdreDeTravailValidationStatus.WaitingForInformation]: "En attente d'informations",
  [OrdreDeTravailValidationStatus.Validated]: 'Validé',
  [OrdreDeTravailValidationStatus.Cancelled]: 'Annulé',
};

const timeSheetEntryValidationStatus = {
  [TimeSheetEntryValidationStatus.Open]: 'Ouvert',
  [TimeSheetEntryValidationStatus.DocumentsReceived]: 'Documents reçus',
  [TimeSheetEntryValidationStatus.Submitted]: 'Soumise',
};

const timeSheetDistributedTimes = {
  status: {
    automatic: 'Suggestion automatique',
    expired: 'Suggestion automatique périmée',
    modified: 'Modifications apportées à la suggestion automatique',
  },
  tooltip: 'Suggestion générée le: {{ date }}',
};

const headers = {
  number: "Numéro de l'OT",
  branch: 'Succursale de répartition',
  operatorsBranches: 'Succursales des grutiers',
  salesBranch: 'Succursale de ventes',
  unitNo: 'No Unité',
  client: 'Client',
  validationStatus: 'Statut de validation',
  operators: 'Grutiers',
  documentStatus: "Statut de l'OT",
  date: 'Date',
  workType: 'Nature du Travail',
  contractMinimum: 'Minimum du Contrat',
  requiredCapacity: 'Capacité requise',
  workDescription: 'Description du Travail',
  maximumWeightToLift: 'Poids Max. à Soulever',
  representativeName: 'Chargé de projet',
  site: 'Chantier',
  timeSheetEntryValidationStatus: 'Statut de validation',
  tags: 'Marqueurs',
};

const list = {
  title: 'Validations',
  headers,
  validationsStatuses,
};

const premiums = {
  title: 'Indemnités',
  addPremium: 'Ajouter',
  numberOfDays: 'Nb de jours',
  numberOfHours: "Nb d'heures",
  hours_one: '{{count}} heure',
  hours_other: '{{count}} heures',
  days_one: '{{count}} jour',
  days_other: '{{count}} jours',
  to: 'à',
  travelledKm: 'Km parcourus',
  [CompilablePremiumsEnum.PreparationPremium]: 'Préparation au travail',
  [NonCompilablePremiumsEnum.CarAllowancePremium]: 'Utilisation du véhicule',
  [NonCompilablePremiumsEnum.MealPremium]: 'Repas',
  [NonCompilablePremiumsEnum.TaxisPremium]: 'Frais taxis',
  [NonCompilablePremiumsEnum.ParkingPremium]: 'Frais stationnement',
  [NonCompilablePremiumsEnum.TravellingExpensesPremium]: 'Frais de déplacement',
  [NonCompilablePremiumsEnum.PensionPremium]: 'Pension',
  [CompilablePremiumsEnum.ManTransportPremium]: "Transport d'hommes",
  [CompilablePremiumsEnum.ManTimePremium]: "Temps d'hommes",
  [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
  [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
  [WorkKindNumberValue.travelExpenses49To72Km]: '49 - 72 km',
  [WorkKindNumberValue.travelExpenses73To88Km]: '73 - 88 km',
  [WorkKindNumberValue.travelExpenses89To119Km]: '89 - 119 km',
  [WorkKindNumberValue.travelExpenses120AndMoreKm]: '120 + km',
  [WorkKindNumberValue.travelExpensesSiteTransfer]: 'Transfert chantier',
  [WorkKindNumberValue.roomAndBoardDay]: 'Jour',
  [WorkKindNumberValue.roomAndBoardTwoPlusWeeks]: '2+ semaines',
  workKind: 'Genre',
  linkedTo: 'Lié à',
  notLinked: 'Non lié',
  columns: {
    premium: 'Indemnité',
    posteDeTravail: 'Poste',
    branch: 'Suc.',
  },
  unknown: 'Saisie incomplète',
  empty: 'Sélectionner',
};

const additionalTimes = {
  title: 'Temps additionnels',
  options: {
    [CompilablePremiumsEnum.ManTransportPremium]: "Transport d'hommes",
    [CompilablePremiumsEnum.ManTimePremium]: "Temps d'hommes",
    [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
    [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
  },
  type: {
    [AdditionalHourType.ManTransport]: "Transport d'hommes",
    [AdditionalHourType.ManTime]: "Temps d'hommes",
    [AdditionalHourType.NoLunchTime]: 'No Lunch',
    [AdditionalHourType.NoBreakTime]: 'No Break',
    empty: 'Sélectionner',
  },
};

const timesheet = {
  title: 'Carte de temps',
  days_one: 'jour',
  days_other: 'jours',
  premiums,
};

const submitDocumentsModal = {
  ordreDeTravail: 'Ordre de travail',
  submitMenu: 'Soumettre toutes les cartes de temps',
  title: 'Soumettre tous les documents?',
  content: `Tous les documents seront soumis à l'AS400.`,
  cancel: 'Annuler',
  submit: 'Soumettre',
};

const submitTimesheetsModal = {
  title: 'Soumettre toutes les cartes de temps?',
  content: `Les cartes de temps seront soumises à l'AS400.`,
  cancel: 'Annuler',
  submit: 'Soumettre',
};

const differingRatesModal = {
  title: 'Taux de ventes différents',
  content: `Attention : l’OT [{{ otNumber }}] contient au moins un code de facturation dont le taux de vente diffère du taux système. Vérifiez les taux de vente avant de soumettre.`,
  cancel: 'Retour',
  submit: 'Conserver les taux',
};

const submitSelectionDocumentsModal = {
  title: 'Sélectionnez les documents validés reliés à l’OT #{{ otNumber }} à soumettre.',
  close: 'Fermer',
  submit_submit: 'Soumettre les documents sélectionnés',
  submit_submitting: 'Soumission des documents...',
  submitCodeDeFacturation: 'Codes de facturation',
  submitMenu: 'Sélectionner les documents à soumettre',
  timeSheetContent: 'Carte de temps liée ({{ employeeNames }})',
  timeSheetNotLinkedContent: 'Carte de temps non liée ({{ employeeNames }})',
  contractAgreementContent: 'Contrat de service #{{ contractAgreementNumber }}',
  codeDeFacturationContent: 'Soumis le {{ submittedAt }} par {{ name }}',
  contractAgreement: 'Contrat de service',
  documentStatus_submitting: 'Soumission',
  documentStatus_submitted: 'Soumis',
  documentStatus_waiting: 'En attente',
  documentStatus_ready: 'Prêt',
  documentStatus_rejected: 'Erreur',
};

const addTimeRangeModal = {
  title: 'Ajouter',
  addTimeRange: 'Plage horaire',
  addCompilablePremium: 'Indemnité compilable',
  indemnityType: 'Type d’indemnité',
  errors: {
    required: 'Requis',
    emptyFields: 'Vous avez des champs vides',
    toGreaterThanFrom: `L'heure de fin doit être plus grande que l'heure de début`,
    invalidPosteDeTravail: 'Poste de travail invalide',
    incorrectRestOrMealTimeRange: `Le temps saisi dans un No break/No lunch est incorrect`,
    invalidGtNumber: 'Le numéro de GT est invalide',
  },
  tableHeader: {
    linkTo: 'Lier au CS',
    linkToOt: 'Lier à OT',
    from: 'De',
    to: 'À',
    quantity: 'Quantité',
    code: 'Poste',
    single: 'Simple',
    singleAndHalf: 'Demi',
    double: 'Double',
    variablePrime: 'Pr. Var',
    automaticPrime: 'Pr. Auto',
    rate: 'Taux',
    gtNumber: '#GT',
    workOrderNumber: 'Équi./WO',
  },
  cancel: 'Annuler',
  add: 'Ajouter',
};

const splitTimeRangeModal = {
  title: 'Séparer une plage horaire',
  addNewTimeRange: 'Ajouter une plage',
  warningTotalHoursQuantity: `Attention, la quantité d'heures saisie est différente de la quantité d'heures de départ.`,
  errors: {
    emptyFields: `Des plages de temps sont vides. Merci de corriger.`,
    overlappingFields: `Certaines plages de temps se chevauchent. Merci de corriger.`,
    incorrectRestOrMealTimeRange: `Le temps saisie dans un No break/No lunch est incorrecte.`,
    minIsFifteenMinutes: `Les plages de temps doivent être de 15 minutes au minimum.`,
  },
  cancel: 'Annuler',
  split: 'Séparer',
};

const waitingForInformationModal = {
  title: "Passer à «En attente d'informations» ?",
  content: 'Veuillez entrer un commentaire...',
  comment: 'Commentaire',
  cancel: 'Annuler',
  confirm: 'Confirmer',
};

const details = {
  tags: {
    add: 'Marquer',
    remove: 'Retirer',
    waiting: 'En attente d’info',
    return: 'Retour en correction',
    errorCount_one: `Une erreur à la soumission de l’Ordre de travail`,
    errorCount_other: `{{count}} erreurs à la soumission de l’Ordre de travail`,
    modal: {
      title: {
        waitingForInformation: 'Marquer en attente d’informations?',
        returnForCorrection: 'Retour en correction',
      },
      comment: 'Commentaire',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      quit: 'Fermer',
    },
  },
  validations: 'Validation',
  submitted: 'Soumise',
  completed: 'Complétée',
  ongoing: 'En cours',
  upcoming: 'À venir',
  submitPopup: 'Validez toutes les cartes de temps et sauvegarder vos changements avant de soumettre',
  contractAgreement: 'Contrat de service',
  timesheet,
  ot: 'ot',
  validationsStatuses,
  button,
  overlapTimeRangeError: 'Plages horaires en chevauchement',
  fromBiggerThanToError: 'La date de début doit être antérieure à la date de fin',
  noTimeRangeError: 'Plage horaire manquante',
  hours: 'heure(s)',
  saveSuccessMessage: "Les modifications des documents liés à l'OT {{otNumber}} ont bien été enregistrées.",
  validationErrorMsg: 'Il y a des erreurs de validation dans le formulaire. Veuillez les corriger avant de sauvegarder.',
  submitErrorMsg: "Une erreur technique est survenue lors de la soumission à l'AS400",
  rateValidationErrorMsg: 'Une erreur technique est survenue lors de la validation des taux',
  submitSuccessMsg: "La validation a été soumise avec succès à l'AS400",
  submitDocumentsModal,
  submitSelectionDocumentsModal,
  differingRatesModal,
  submitTimesheetsModal,
  splitTimeRangeModal,
  addTimeRangeModal,
  waitingForInformationModal,
  totalCraneTimeError:
    'Les Heures de Service de Grue du Contrat de service ne correspondent pas aux heures indiquées dans la Carte de temps',
  reviewedBy: 'Révisé par',
};

const serviceHourTable = {
  title: 'Heures de service',
  total: 'Total facturable',
  craneService: 'Service de grue',
  addButtonLabel: 'Ajouter',
  noData: 'Aucune heure de service',
  fromBiggerThanToError: "L'heure de début doit être antérieure à l'heure de fin",
  overlapTimeRangeError: 'Plages horaires en chevauchement',
  unmatchTotalExpectationError:
    "Les heures de service de grue ne respectent pas les heures minimales de l'appel de service ({{expectedServiceHoursAmount}} heures)",
  header: {
    from: 'De',
    to: 'À',
    activity: 'Activité',
    single: 'Simp.',
    singleAndAHalf: 'Demi',
    double: 'Dou.',
    quantity: 'Qté',
    dispatch: 'Rép.',
  },
  options: {
    lunchBreak: 'Repas',
    serviceHour: 'Fact.',
  },
};

const inlineTimesheet = {
  title: 'Temps',
  addTime: 'Ajouter une plage',
  addTimeRange: 'Plage horaire',
  addCompilablePremium: 'Imdemnité compilable',
  indemnityType: 'Type d’indemnité',
  header: {
    linkedTo: 'Lié à',
    from: 'De',
    to: 'À',
    qty: 'Qté',
    code: 'Poste',
    branch: 'Suc.',
    single: 'Simp.',
    singleAndHalf: 'Demi',
    double: 'Dou.',
    variablePrime: 'P. Var.',
    automaticPrime: 'P. Aut.',
    rate: 'Taux',
    notReceived: 'Carte de temps non reçue',
    gtNumber: '#GT',
    workOrderNumber: 'Équi./WO',
  },
  footer: {
    total: 'Total',
  },
};

const detailTabs = {
  billing: 'Facturation',
  pay: 'Paie',
  operators: 'Grutiers',
  dispatch: 'Répartition',
  branches: 'Succursales',
  readonly: 'Lecture',
  only: 'Seulement',
  noBranchSelected: 'Aucune succursale sélectionnée',
};

const rateModifiedModal = {
  title: 'Justifier les modifications aux taux',
  headers: {
    code: 'Code',
    suggestedRate: 'Taux suggéré',
    modifiedRate: 'Taux modifié',
    justification: 'Justification',
  },
  enterJustification: 'Entrer une justification...',
};

const addCommentModal = {
  title: 'Ajouter un commentaire',
  subtitle: "Inscrire un commentaire s'appliquant à",
  comment: 'Commentaire',
};

const prefacturation = {
  title: 'Codes de facturation',
  add: 'Ajouter',
  header: {
    codeDeFacturation: 'Code de facturation',
    description: 'Description',
    quantity: 'Quantité',
  },
  crane: 'Grue',
  labor: "Main d'oeuvre",
  accessories: 'Accessoires',
  otherFees: 'Autres frais',
  toBill: 'À facturer',
  notRecuringBilling: 'Codes non récurrents',
  tableHeaders: {
    codeDeFacturation: 'Code',
    description: 'Description',
    accessory: 'Accessoire',
    accessoryType: "Type d'accessoire",
    labor: `Main d'oeuvre`,
    fees: 'Frais',
    quantity: 'Quantité',
    quantityShort: 'Qté',
    rate: 'Taux',
    billingOT: 'OT de facturation',
    billable: 'Fact.',
    gtNumber: '#GT',
  },
  emptyState: 'Aucun code de facturation de ce type.',
  errors: {
    noCodeDeFacturationInDouble: 'Ce code de facturation existe déjà.',
  },
  commentsInformation: 'Commentaires & Informations',
  comments: 'Commentaires',
  comment: 'Ceci est un commentaire',
  noPo: '# Commande',
  total: 'Total',
  disabled: 'Ce code est lié',
  disabled_fromOT: "Ce code provient de l'OT",
  disabled_linkedToCurrentOT: 'Ce code est lié au présent OT',
  disabled_linkedToAnotherOT: 'Ce code est lié à un autre OT',
  disabled_linkedToNonRecurrent: 'Ce code est lié à un code de facturation non récurrent',
  disabled_notSaved: 'Ce code n’a pas été sauvegardé',
  you: 'Vous',
  saleRateError: 'Erreur de récupération de taux de vente',
  details: 'Détails.',
  noDetails: 'Aucun détail disponible',
  rateModifiedModal,
  addCommentModal,
  gtNumberOnOt: 'Un #GT a été appliqué sur l’OT',
};

const validation = {
  noTimeRangeOverlap: 'Les intervalles ne peuvent pas se chevaucher',
  invalidNoLunchTimeRange: 'Un intervalle de type "no lunch break" doit avoir une durée de 30 minutes',
  invalidNoBreakTimeRange: 'Un intervalle de type "no break time" doit avoir une durée de 15 minutes',
  invalidPreparationTimeRange: 'Un intervalle de type "préparation" doit avoir une durée de 1 ou 1.25 heure',
  toTimeBeforeFromTime: 'Le temps de fin ne peut pas être avant le temps de début',
  mustMatchDuration: 'La somme de toutes les heures doit être égale à la durée',
  invalidWorkType: 'La nature du travail est invalide',
  invalidWorkKind: 'Le genre du travail invalide',
  invalidGtNumber: 'Le numéro de GT est invalide',
};

const overlapWarning = {
  title: 'Plus d’un premier grutier en même temps sur l’OT.',
};

const additionalFees = {
  meals: {
    label: 'Repas',
    editLabel: 'Repas',
  },
  carAllowances: {
    label: 'Utilisation du véhicule',
    editLabel: 'Utilisation du véhicule (km par chauffeur)',
  },
  pensions: {
    label: 'Pension',
    editLabel: 'Pension (jours par personne) ',
  },
  preparations: {
    label: 'Preparation',
    editLabel: 'Preparation (jours par personne) ',
  },
  travellingExpenses: 'Frais de déplacement',
  edit: {
    travellingExpenses: {
      to: 'à',
      kmTravelled: 'Km parcourus',
    },
  },
};

const dailyTimeSheet = {
  noData: 'Aucune carte de temps à afficher.',
  status: {
    submitted: 'Soumis',
    completed: 'Terminée',
    ongoing: 'En cours',
  },
  linkedHours: 'Lié à',
  unlinkedHours: 'Non lié',
  total: 'Total',
  totalWeekly: 'Total hebdo.',
  pending: 'En attente',
  compilableHours: {
    noData: 'Aucune heure facturable',
    from: 'De',
    to: 'À',
    quantity: 'Qté',
    quantity_tip: 'Quantité',
    poste: 'Poste',
    branch_tip: 'Succursale',
    branch: 'Suc.',
    workOrderNumber: 'Éq/WO',
    workOrderNumber_tip: 'Équipement/Work Order',
    gtNumber: '#GT',
    single: 'Simp.',
    single_tip: 'Simple',
    singleAndHalf: 'Demi',
    double: 'Double',
    autoPrime: 'P. Aut.',
    autoPrime_tip: 'Prime automatique',
    variablePrime: 'P. var.',
    variablePrime_tip: 'Prime variable',
    rate: 'Taux',
  },
  nonCompilableHours: {
    noData: 'Aucune heure non facturable',
    premium: 'Indemnité',
    poste: 'Poste',
    branch_tip: 'Succursale',
    branch: 'Suc.',
    gtNumber: '#GT',
  },
  comments: {
    title: 'Commentaires',
    noData: 'Aucun commentaire',
  },
  attachments: {
    title: 'Pièces jointes',
    noData: 'Aucune pièce jointe',
    notUploaded: 'Cette pièce jointe n’a toujours pas été téléversée',
    preview: 'Voir la pièce jointe',
    download: 'Télécharger la pièce jointe',
    name: 'Nom',
  },
};

const additionalHours = {
  title: 'Heures supplémentaires',
  noData: 'Aucune heure supplémentaire',
};

const prompt = {
  informations: 'Informations',
  title: "Informations de l'appel de service",
  client: 'Nom du client',
  noClient: 'Aucun Client',
  worksite: 'Nom du chantier',
  noWorksite: 'Aucun chantier',
  workProgress: 'Progression des travaux',
  tracking: 'Suivi',
};

const codeDeFacturationNotDispatchedModal = {
  title: 'Codes de facturation non-répartis',
  content: "Il s'agit du dernier OT de l'appel de service ; tous les codes de facturation n'ont pas été répartis",
  cancel: 'Annuler',
  confirm: 'Créer un OT suivant',
};

export const validations = {
  additionalFees,
  dailyTimeSheet,
  inlineTimesheet,
  prefacturation,
  list,
  additionalTimes,
  details,
  serviceHourTable,
  additionalHours,
  detailTabs,
  timeSheetEntryValidationStatus,
  timeSheetDistributedTimes,
  validation,
  overlapWarning,
  manuallyCreated: 'Créée manuellement',
  prompt,
  codeDeFacturationNotDispatchedModal,
};
