import { SxProps, TextField, TextFieldProps } from '@mui/material';
import { CSSProperties, forwardRef, useMemo } from 'react';
import { inlineInputStyle } from './InlineMuiInputStyle';

type Props = Omit<TextFieldProps, 'select'>;

export const InlineSelectInput = forwardRef<HTMLDivElement, Props>(({ value, sx, slotProps: receivedSlotProps, ...props }: Props, ref) => {
  const slotProps = useMemo(() => {
    const inputSlotProps = receivedSlotProps?.input as { style?: CSSProperties; sx: SxProps } | undefined;
    return {
      ...receivedSlotProps,
      input: {
        ...inputSlotProps,
        style: { cursor: 'pointer', ...inputSlotProps?.style },
        sx: {
          fontSize: '0.875rem',
          ...inlineInputStyle,
          ...inputSlotProps?.sx,
        },
      },
    };
  }, [receivedSlotProps]);

  return <TextField ref={ref} {...props} select value={value || ''} sx={{ fontSize: '0.875rem', ...sx }} slotProps={slotProps} />;
});
